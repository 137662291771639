.add-new-user-form {
  display: flex;
  flex-wrap: wrap;
}

.add-new-user-form-input {
  margin: 0px !important;
  display: flex;
  margin-bottom: 10px !important;
  width: 31% !important;
}

.ui[class*="right ribbon"].label {
  left: calc(56% + 1rem + 1.2em) !important;
  height: 27px !important;
}

.textCenter {
  display: flex !important;
  text-align: center !important;
}

.ribbon-label {
  height: 20px !important;
}

.icon-font-size {
  padding-top: 10% !important;
  font-size: 18px !important;
  cursor: pointer;
}

.circular-label {

  position: absolute !important;
  left: -13px;
  top: -10px;
}


.user-feed-profile {

  background-color: rgb(242 242 242);
  padding: 4px 6px;
  border-radius: 30px;
}

.MetaData {
  font-size: 12px !important;
}

.no-border.ui.input>input {
  border: none !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

.no-border.ui.input>input:focus {
  border: none !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

/* .fixed-position-inner {
  position: fixed;
  left: 15%;
  right: 0%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 0px 8px 1px #ccc;
  padding: 15px;
} */

.fixed-position-inner {
  position: fixed;
  left: 15%;
  right: 0%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 0px 8px 1px #ccc;
  padding: 15px;
  transition: all 0.3s ease-in-out;
  /* Smooth transition */
}

/* Tablet: Adjust left margin */
@media (max-width: 1012px) {
  .fixed-position-inner {
    left: 40% !important;
    /* Reduce left space */
    right: 0% !important;
    padding: 12px !important;
  }
}

/* Tablet: Adjust left margin */
@media (max-width: 1020px) {
  .fixed-position-inner {
    left: 40% !important;
    /* Reduce left space */
    right: 0% !important;
    padding: 12px !important;
  }
}

/* Tablet: Adjust left margin */
@media (max-width: 1022px) {
  .fixed-position-inner {
    left: 40% !important;
    /* Reduce left space */
    right: 0% !important;
    padding: 12px !important;
  }
}

/* Tablet: Adjust left margin */
@media (max-width: 1024px) {
  .fixed-position-inner {
    left: 40% !important;
    /* Reduce left space */
    right: 0% !important;
    padding: 12px !important;
  }
}

/* Mobile: Make it full-width */
@media (max-width: 768px) {
  .fixed-position-inner {
    left: 0 !important;
    /* Full width */
    right: 0 !important;
    padding: 10px !important;
  }
}


/* Small Mobile: Adjust padding */
@media (max-width: 480px) {
  .fixed-position-inner {
    left: 0 !important;
    right: 0 !important;
    padding: 8px !important;
  }
}


@media only screen and (max-width: 1023px) {
  .ui.grid .custom-userlist .seven.wide.column.custom-userlist-4 {
    width: 80% !important;
  }

  .ui.grid .custom-userlist .one.wide.column.custom-userlist-5 {
    width: 40px !important;
    padding-top: 13px;
    padding-left: 0;
    padding-right: 0;
  }

  .ui.grid .custom-userlist .one.wide.column.custom-userlist-1 {
    width: 40px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .ui.grid .custom-userlist .four.wide.column.custom-userlist-3 {
    width: 190px !important;
  }

  .ui.grid .custom-userlist .three.wide.column.custom-userlist-1 {
    width: 140px !important;
  }

  .custom-userlist-4 .ui.secondary.pointing.menu .item {
    padding: 5px;
    min-width: 2em;
  }

  .user-full {}

  .ui[class*="five column"].grid>.row.user-full>.column {
    width: 100% !important;
    box-shadow: none !important;
  }

  .ui[class*="right ribbon"].label {}

  .ui[class*="right ribbon"].label {
    left: calc(-7% + 0rem + 0em) !important;
    padding-left: calc(1rem + 1.2em) !important;
    padding-right: calc(1rem + 1.2em) !important;
    transform: translateX(-0%) !important;
    border-radius: 0.28571429rem !important;
  }

  .full-w2 {
    justify-content: space-between;
  }

  .ui[class*="right ribbon"].label:after {
    border-width: 0 !important;
  }

  .icon-font-size {
    padding-top: 3% !important;
  }

}

.table-group-user {
  margin-top: 4.6rem;
}

.crousel-slider .control-arrow {
  height: 100px !important;
  margin-top: 100px !important;
  border-radius: 15px;
}

@media only screen and (max-width: 1490px) {
  .table-group-user {
    font-size: 12px;
  }

  .resp-header {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1355px) {
  .table-group-user {
    margin-top: 5.8rem;
    font-size: 12px;
  }

  .resp-header {
    font-size: 12px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 650px) {
  .ui.grid .custom-userlist .four.wide.column.custom-userlist-3 {
    width: 115px !important;
  }
}


i.icon {
  cursor: pointer;
}


.responsive-image {
  position: absolute !important;
  top: 3% !important;
  left: 65% !important;
  width: 15% !important;
  max-width: 75px !important;
  height: auto !important;
}

/* 🖥️ Large Screens (1920px and up) */
@media (min-width: 1920px) {
  .responsive-image {
    left: 70% !important;
    width: 15% !important;
    max-width: 80px !important;
  }
}

/* 🖥️ Extra-Large Screens (2560px and up - 2K, 4K screens) */
@media (min-width: 2560px) {
  .responsive-image {
    left: 80% !important;
    width: 6% !important;
    max-width: 90px !important;
  }
}

/* 💻 Medium Laptops (1366px - 1440px) */
@media (max-width: 1440px) {
  .responsive-image {
    left: 65% !important;
    width: 12% !important;
    max-width: 70px !important;
  }
}

/* 💻 Small Laptops (1280px - 1366px) */
@media (max-width: 1366px) {
  .responsive-image {
    left: 60% !important;
    width: 14% !important;
    max-width: 65px !important;
  }
}

/* 📱 Tablets & Smaller Screens (Below 1024px) */
@media (max-width: 1024px) {
  .responsive-image {
    left: 55% !important;
    width: 16% !important;
    max-width: 60px !important;
  }
}

/* 📱 Mobile Screens (Below 768px) */
@media (max-width: 768px) {
  .responsive-image {
    left: 50% !important;
    width: 18% !important;
    max-width: 55px !important;
  }
}

/* 📱 Very Small Screens (Below 480px) */
@media (max-width: 480px) {
  .responsive-image {
    left: 45% !important;
    width: 20% !important;
    max-width: 50px !important;
  }
}



.box-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}



.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}