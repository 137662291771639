.panelContainer {
  display: flex;
  width: 100%;
  margin-top: 67px;
}

.leftSideDrawer {
  width: 15%;
  max-height: 100vh;
  overflow-y: scroll;
}

.rightSideDrawer {
  width: 85%;
  /* padding: 0px 15px; */
}

.rightSideDrawer h1 {
  text-align: center;
  margin: 20px 0;
  font-family: "Cambo", "Times New Roman", serif;
  font-weight: bold;
}

.sidebar-admin {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  text-align: center;
  height: 100%;
}

.sidebar-admin h1 {
  margin: 24px 0;
  color: #fff;
  font-family: "Cambo", "Times New Roman", serif;
  font-weight: 700;
}

.sidebar-admin ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar-admin ul li a {
  display: flex;
  justify-content: left;
  color: white;
  text-decoration: none;
  align-items: center;
  transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sidebar-admin ul li a img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.sidebar-admin ul li:hover {
  /* background-color: #057a34; */
  background-color: #323634 !important;
}

/* .container-admin{
  
  } */

.accordion-box-admin {
  border: none;
  color: white;
  cursor: pointer;
  padding: 1px 0;
}

.accordion-title-admin {
  padding: 10px 50px;
  /* background-color: #87c042; */
  background-color: rgb(97 174 231) !important;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resource-menu-title {
  padding: 10px 5px;
  background-color: rgb(242 113 28) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordion-content-admin {
  padding: 10px 5px;
  /* background-color: #32373c; */
  /* background-color: rgb(75, 161, 74) !important; */
  background: linear-gradient(rgb(120 179 223), rgb(50 114 163));
}

.accordion-content-admin li {
  list-style-type: none;
  padding: 10px 5px;
}

.accordion-title-admin:hover {
  background-color: #74a932;
  transition: all 0.3s ease-in-out;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.container {
  display: flex;
}

.sidebar {
  padding: 10px;
  width: 40%;
  background: lightblue;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .leftSideDrawer {
    width: 40%;
  }

  .rightSideDrawer {
    width: 60%;
  }
}

@media screen and (max-width: 520px) {
  .leftSideDrawer {
    width: 100%;
  }

  .rightSideDrawer {
    width: 100%;
  }

  .panelContainer {
    flex-direction: column;
  }

}