* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

/* DO-NOT-REMOVE => ISSUE : https://stackoverflow.com/questions/69051008/react-injecting-iframe-with-max-z-index-on-reload-after-changes-development */
/* body>iframe[style*='2147483647'] {
  display: none;
} */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}